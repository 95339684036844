 body {
  margin: 0;
  background: linear-gradient(30deg, rgba(11,48,237,1) 7%, rgba(3,24,105,1) 69%, rgba(1,19,80,1) 87%);
  font-family: 'Carlito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Carlito', sans-serif;
} 
.top-btn {
  font-size: 2.1rem;
  width: 2rem;
  height: 2rem;
  color: #0318B0;
  background-color: #fff;
opacity:0.1;
  border-radius: 50%;
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
  --icon {
    animation: gototop 1.2s linear infinite alternate-reverse;
  }
  @keyframes gototop {
    0% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(1rem);
    }
  }
  .css-b62m3t-container.css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 40px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    margin-top: 11px;
    border-width: 1px;
    box-sizing: border-box;
    margin-top:7px;
}



