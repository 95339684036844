.center-image-container-ql {
  /* height: 70%; */
  text-align: center; 
  padding-top: 1.5%;
  padding-left: 0%;
  /* object-fit: contain; */
}
.center-image-container-ql img {
  width: auto;
  height: 80vh;
}

.sem-logo {
  display: flex;
  max-width: 100%;
}
.sem-logo img {
  height: auto;
  width: 144px;
  margin: 0px 10px 20px 0;
}

.sem-logo h2 {
  font-family: "Carlito", sans-serif;
  font-weight: 500;
  text-align: center;
  /* margin-top: 22px; */
}
/*.center-image-container-ql{
width:100%;
height:auto;
text-align: center;*/

/* .center-image-container-ql img{
  width: 100%;
 height: auto;
 object-position:center;
  display: block;
  
} */

@media (max-width: 576px) {
  .sem-logo {
    /* height: 80px; */
    flex-direction: column;
  }
  .sem-logo img {
    height: 32px;
    width: 25%;
    margin: 0;
  }
  .sem-logo h2 {
    font-size: 17px !important;
    text-align: left;
  }
}

/*@media (max-width: 576px) {
  .sem-logo {
    flex-direction: row;
    align-items: center;
  }
  
  .sem-logo img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
*/

#OntoQl {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
  justify-content: center;
}

@media (max-width: 992px) {
  .center-image-container-ql img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 576px){
  #OntoQl {
    padding-top: 20px;
  }
  
}
