.our-vision {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 1.5%;
  padding-left: 1% ;
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.vision-container {
  /* margin-top:4em; */
  /* width:50%; */
    /* height: 80%; */
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr, 1fr;
  align-items: center;
  justify-content: space-between;
  /* gap: 3rem; */
}
.vision-left {
  display: grid;
}
.tick {
  display: flex;
  gap: 15px;
  margin-top: 2%;
}
.tick img {
  height: 35px;
}
.tick h4 {
  font-size: 25px;
  font-weight: 580;
}
.tick-m {
  /* position: relative;
  left: -420px; */
  position: absolute;
  left: 3em;
}
.tick-m img {
  height: 35px;
}
.goverance {
  height: 95px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(7deg, #1809fb, #f50af9);
  border-radius: 40px;
}

.divide {
  display: flex;
}
.design {
  height: 95px;
  margin-bottom: 3px;
  /* width: 250px; */
  background: linear-gradient(219deg, #8648e9, #ada7ab);
  display: flex;
  justify-content: center;
  align-items: center;
}
.product {
  height: 95px;
  /* width: 250px; */
  background: linear-gradient(1deg, #9332b3, #c30580);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modern {
  position: relative;
  height: 95px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: linear-gradient(7deg, #1809fb, #f50af9);
}

.rec {
  height: 80px;
  margin-bottom: 3px;
  width: 250px;
  background-color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 8px;
  box-sizing: border-box;
}

.design,
.product {
  flex: 0.5;
  margin-bottom: 15px;
  border-radius: 40px;
}

.goverance {
  margin-bottom: 15px;
}

@media (max-width: 992px) {
  .goverance,
  .modern {
    width: 100%;
  }

  .vision-container {
    margin-bottom: 2rem;
  }
  .tick {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    flex-wrap: nowrap;
  }

  .vision-left {
    margin-bottom: 0;
    text-align: left;
  }

  .vision-right {
    text-align: right;
  }
  .divide {
    gap: 4px;
  }
  .design {
    margin-right: 1rem;
  }
}

@media (max-width: 576px) {
  .vision-container {
    height: auto;
    gap: 5px;
  }
  .design,
  .product,
  .goverance,
  .modern {
    font-size: 12px;
  }
  .divide {
    gap: 4px;
  }
  #ourvision h2{
    margin-top: 0!important;
  }
  .tick {
    margin-top: 0;
}
.tick img {
  height: 22px;
}
.tick h4 {
  font-size: 16px;
}
}
