/* #banner {
  padding: 0 5%;
} */

.BBanner-video {
  height: 100%;
  text-align: center;
  /* object-fit: contain; */
}
.BBanner-video video {
  width: auto;
  height: 100%;
  min-width: 320px;
  max-width: 1366px;
}

.vjs-tech{
  object-fit:cover;
}