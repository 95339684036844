.sphere-logo {
  display: flex; 
  padding-top: 1.5%;
  padding-left: 0%;
  /* height: 100px; */
}
.sphere-logo img {
  width: 14%;
  margin-right: 0.5%;
  /* margin: 20px 10px 20px 0; */
}
.sphere-logo h2 {
  font-family: "Carlito", sans-serif;
  font-weight: 500;
  text-align: center;
}
.image-container img {
  max-width: 100%;
}

@media (max-width: 576px) {
  .sphere-logo {
    /* height: 80px; */
    flex-direction: column;
  }
  .sphere-logo img {
    height: 32px;
    width: 40%;
  }
  .sphere-logo h2 {
    font-size: 17px !important;
    text-align: left;
  }
}
