.onto-logo {
  display: flex;
  max-width: 100%;
  padding-top: 1.5%;
  padding-left: 0%;
  /* height: 100px; */
}

.onto-logo img {
  width: 12%;
  margin-right: 0.5%;
  /* aspect-ratio: ; */
  /* height: auto; */
  /* width: 193px; */
  /* margin: 20px 10px 20px 0; */
}

.onto-logo h2 {
  font-family: "Carlito", sans-serif;
  font-weight: 500;
  text-align: center;
}

.center-image-container img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 576px) {

  /* .onto-logo {
    height: 80px;
  } */

  .onto-logo{
    flex-direction: column;
  }

  .onto-logo img {
    height: 36px;
    width: 40%;
    margin-right: 2%;
  }

  .onto-logo h2 {
    font-size: 17px !important;
    text-align: left;
  }
}