@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

.newContact {
  /*margin-top:3rem */
  padding-top: 1%;
  padding-left: 1%;
  height: 100vh;
  display: grid;
  /* justify-content: space-between; */
}
.row1 {
  padding: 0 0px;
}
.contact-info svg {
  font-size: 38px;
  color: #d117ff;
}

/* .contact-bg{
    height: 40vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(image/contact-bg.jpg);
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
.contact-bg h3 {
  font-size: 1.3rem;
  font-weight: 400;
}
.contact-bg h2 {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0.4rem 0;
  letter-spacing: 4px;
}
.line div {
  margin: 0 0.2rem;
}
.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #d117ff;
  border-radius: 5px;
}
.line {
  display: flex;
  align-items: center;
}
.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #d117ff;
  border-radius: 50%;
}
.text {
  font-weight: 300;
  opacity: 0.9;
}
.contact-bg .text {
  margin: 1.6rem 0;
}
.contact-body {
  /* max-width: 1320px; */
  margin: 0 auto;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}
.contact-info {
  display: flex !important;
  flex-direction: column;
  /* width: 30%; */
  /* padding: 2rem 0; */
}

.contact-mid-section{
  /* width: 30%; */
  /* height: 100%; */
  /* display:flex; */
  /* flex-direction:column; */
}

.single-box{
  width:12vw;
  margin: 0px auto;
  text-align: left;
}

.formcontact{
  /* width: 40%; */
  padding-right: 2%;
}

.contact-info span {
  display: block;
}
.contact-info div {
  margin: 0.8rem 0;
  /* padding: 1rem; */
}
.contact-info span .fas {
  font-size: 2rem;
  padding-bottom: 0.9rem;
  color: #d117ff;
}
.contact-info div span:nth-child(2) {
  font-weight: 500;
  font-size: 1.1rem;
}
.contact-info .text {
  padding-top: 0.4rem;
}
.contact-details {
  display: flex;
  text-align: left;
}
.contact-details > span {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.contact-form {
  padding: 2rem 0;
}
.contact-form form {
  padding-bottom: 1rem;
}
.form-control {
  width: 100%;
  border: 1.5px solid #c7c7c7;
  border-radius: 5px;
  padding: 0.7rem;
  margin: 0.6rem 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  outline: 0;
}
.form-control:focus {
  box-shadow: 0 0 6px -3px rgba(48, 48, 48, 1);
}
.contact-form form div {
  /* display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0.7rem; */
}
.send-btn {
  font-family: "Carlito", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  color: #fff;
  background: #d117ff;
  border: none;
  border-radius: 5px;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  transition: all 0.4s ease;
  width: 100%;
}
.send-btn:hover {
  opacity: 0.8;
}
.contact-form > div img {
  width: 85%;
}
.contact-form > div {
  margin: 0 auto;
  text-align: center;
}
.contact-footer {
  padding: 2rem 0;
  background: transparent;
}
.contact-footer h3 {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
}
.social-links {
  display: flex;
  justify-content: center;

  gap: 20px;
}
.social-links a {
  text-decoration: none;
  width: 40px;
  height: 40px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  transition: all 0.4s ease;
}
.social-links a:hover {
  color: #d117ff;
  border-color: #d117ff;
}

.social-links svg {
  font-size: 24px;
  color: #d117ff;
}
.map {
  margin: 0 -70px !important;
  padding: 0 -70px !important;
}
@media screen and (min-width: 768px) {
  .contact-bg .text {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .contact-bg .text {
    width: 50%;
  }
  .contact-form {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
}

@media screen and (min-width: 1200px) {
  .contact-info {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 576px) {
  .contact-form {
    display: flex;
    flex-direction: column-reverse;
  }
  .formcontact .g-3 {
    --bs-gutter-y: 0;
  }
  .form-control,
  .send-btn {
    margin: 0;
    margin-top: 10px;
  }
  .contact-info svg {
    font-size: 24px;
  }
  .formcontact-footer{
    margin: 0!important;
}
.contact-info div {
  margin: 0.4rem 0;
}

.formcontact{
  margin-bottom: 3% !important;
}
}
