.left-use img {
  max-height: 50%;
  min-height: 300px;
  margin-top: 23px;
}
.usecase-text {
  display: grid;
}
.usecase-text p {
  justify-content: flex-start;
  font-size: 1.4rem;
}
/* .usecase-header {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
} */

.usecase---container {
  max-width: 100%;
  height: 90%;
}
.rectangle-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* margin: 17px; */
}

.rectangle-first img {
  max-width: 34%;
}

.rectangle-first {
  height: 92px;
  width: 60%;
  display: flex;
  padding-left: 6px;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(7deg, #9504b4, #e96091);
  border-radius: 45px;
  gap: 20px;
}
.rectangle-second {
  height: 92px;
  width: 60%;
  display: grid;

  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(0deg, #0382d5, #002ee0);
  border-radius: 45px;
  gap: 20px;
}
.rectangle-third {
  height: 92px;
  width: 60%;
  display: grid;

  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(7deg, #1809fb, #f50af9);
  border-radius: 45px;
  gap: 20px;
}
.rectangle-first p {
  left: 200px;
  margin-left: 110px;
}

.rectangle-second p {
  left: 200px;
  margin-left: 110px;
}
.rectangle-third p {
  left: 200px;
  margin-left: 110px;
}
.in-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #fff;
  margin: 0px;
  position: absolute;
}
.in-circle img {
  max-width: 61%;
  margin-top: 11px;
  margin-left: 1px;
}
.in-circle1 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #fff;
  margin: 6px;
  position: absolute;
}
.in-circle1 img {
  max-width: 61%;
  margin-top: 11px;
  margin-left: 1px;
}
.in-circle2 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #fff;
  margin: 6px;
  position: absolute;
}
.in-circle2 img {
  width: 75%;
  margin-top: 4px;
  margin-left: 7px;
}
.db img {
  max-width: 30%;
  margin-left: 44px;
  margin-top: -94px;
}
.datab img {
  max-width: 30%;
  margin-left: 44px;
  margin-top: -94px;
}

.usecaseimage {
  height: 50%;
  margin-top: 5rem;
}
.usecaseimage img {
  margin-top: -6rem;
  max-width: 70%;
  margin-left: 200px;
}

@media (max-width: 992px) {
  .rectangle-list {
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .rectangle-first,
  .rectangle-second,
  .rectangle-third {
    width: 100%;
  }
}

#Home1{
  height: 100%;
}
